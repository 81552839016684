import React from "react"
import "@fontsource/space-grotesk"

class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
   // silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

//export const wrapRootElement = ({ element }) => {
//  return <SessionCheck>{element}</SessionCheck>
//}


//const addScript = url => {


//}

//export const onClientEntry = () => {

//}
export { default as wrapRootElement } from './src/redux/ReduxWrapper'
