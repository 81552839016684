import React from "react";
import { Provider } from "react-redux";
import { createStore as reduxCreateStore, compose } from "redux";
import rootReducer from "./reducers";

let composeEnhancers;

const createStore = () =>
  reduxCreateStore(
    rootReducer
    //,
    //(composeEnhancers =
    //  typeof window === "object"
    //    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
    //    : compose)
  );

export default ({ element }) => (
  <Provider store={createStore()}>{element}</Provider>
);
